<template>
   <div class="col-12">
      <div class="card hover mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body py-10 px-0">
            <div class="row row-cols-3 text-center">
               <div class="col">{{ ticket.pdv ? ticket.pdv : ticket.operador }}</div>
               <div class="col">
                  <span class="cursor-pointer" @click="buscarComprovantes">
                     {{ parseFloat(ticket.quantidade).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}
                  </span>
               </div>
               <div class="col weight-600">
                  <span class="cursor-pointer" @click="buscarComprovantes">
                     <small class="font-10 d-none d-sm-inline">R$</small> {{ parseFloat(ticket.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Ticket',
   props: ['ticket', 'index'],
   methods: {
      buscarComprovantes : function () {
         this.$emit('buscarComprovantes', {
            'nomeOperador': this.ticket.operador,
            'numeroPdv': this.ticket.pdv,
            'operador': this.ticket.idOperador,
            'pdv': this.ticket.idPdv,
            'formaPagamento': 'TOTAL'
         })
      }
   }
}

</script>

<style scoped>

* {
   font-size: 13.5px;
}

</style>